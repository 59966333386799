import React, { useState, useEffect } from "react";
import API from "../../utils/axios";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Box,
  Tooltip,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import DropArrowIcon from "../../assets/images/DropArrowIcon.png";
import ManagePermission from "./ManagePermission";
import { getEnableUserList, setRoleData } from "../../redux/actions";
import FrachtDeleteDialogBox from "../../commonControls/frachtDeleteDialogBox";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import Loader from "../UI/Loader/Loader";
import Toast from "../UI/Toast/Toast";
const useStyles = makeStyles((theme) => ({
  accordian: {
    backgroundColor: theme.palette.color.secondary,
    boxShadow: "none",
  },
  accordianSummary: {
    flexDirection: "row-reverse",
    padding: "0px",
    minHeight: "auto !important",
    "& .MuiAccordionSummary-content": {
      margin: "0 !important",
    },
    "& .Mui-expanded": {
      border: "none",
      margin: "0px",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      transform: "rotate(-90deg)",
      justifyContent: "center",
      alignItems: "center",
      height: "32px",
      width: "32px",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(0deg)",
    },
    "& img": {
      height: "20px",
      width: "20px",
    },
  },
  accordianDetails: {
    margin: "0px 0px 0px 20px !important",
    borderTop: "1px solid #CFD4D9",
  },
  pTypography: {
    ...theme.typography.body2,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px",
    letterSpacing: "0.01px",
    color: theme.palette.color.cyanBlueC1,
    paddingLeft: "20px",
    "& p": {
      letterSpacing: "0.01px",
      color: theme.palette.color.cyanBlueC1,
      ...theme.typography.body2,
    },
  },
  childTypography: {
    ...theme.typography.body2,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px",
    letterSpacing: "0.01px",
    color: theme.palette.color.cyanBlueC1,
    paddingLeft: "40px",
    "& p": {
      letterSpacing: "0.01px",
      color: theme.palette.color.cyanBlueC1,
      ...theme.typography.body2,
    },
  },
  childChildTypography: {
    ...theme.typography.body2,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px",
    letterSpacing: "0.01px",
    color: theme.palette.color.cyanBlueC1,
    paddingLeft: "120px",
    "& p": {
      letterSpacing: "0.01px",
      color: theme.palette.color.cyanBlueC1,
      ...theme.typography.body2,
    },
  },
}));

function getColorForDropDown(option) {
  switch (option) {
    case "3":
      return "Azure";
    case "2":
      return "PowderBlue";

    default:
      return "PowderBlue";
  }
}

const PermissionDropdown = ({
  id,
  value,
  onChange,
  item,
  classes,
  isSubMenu,
}) => {
  const handelOnclick = (e, id, item) => {
    const clickedValue = e.target.getAttribute("data-value");
    if (clickedValue === value && value === "3") {
      onChange(id, value, item);
    }
  };

  return (
    <FormControl
      margin="dense"
      size="small"
      sx={{ minWidth: 180, padding: isSubMenu ? "4px" : "4px 18px" }}
    >
      <Select
        labelId={`permission-label-${id}`}
        value={value || ""}
        onChange={(e) => onChange(id, e.target.value, item)}
        inputProps={{
          style: {
            height: "28px", // Adjust height as needed
            fontSize: "14px",
            fontWeight: "normal",
          },
        }}
        onClick={(e) => {
          handelOnclick(e, id, item);
        }}
      >
        <MenuItem
          value="2"
          style={{
            background: getColorForDropDown("2"),
          }}
        >
          <Typography className={classes.pTypography} data-value="2">
            Disable
          </Typography>
        </MenuItem>
        <MenuItem
          value="3"
          style={{
            background: getColorForDropDown("3"),
          }}
        >
          <Typography className={classes.pTypography} data-value="3">
            Enable / Modify
          </Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

const AppPermission = ({
  rolePermissions,
  editRoleId,
  roleHierarchy,
  userOrganizationId,
  isEdit,
  showSetting = true,
  callDefaultAPI = true,
  orgInfo,
  setOrgInfo,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modules, setModules] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [permissionId, setPermissionId] = useState({});
  const [changedPermissions, setChangedPermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const { user, organizationId } = useSelector((state) => state.LoginUser);
  const navigate = useNavigate();
  const [initialPermissions, setInitialPermissions] = useState({});
  // Manage Permissions drawer
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState(null);
  const [showBiInput, setShowBiInput] = useState(false);
  const [disableMsg, setDisableMsg] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState(false);
  const [isDisableload, setIsDisableload] = useState(false);

  const openSidePanel = (event, show, displayName, name) => {
    event?.preventDefault();
    if (
      event?.type === "keydown" ||
      event?.key === "Tab" ||
      event?.key === "Shift"
    ) {
      return;
    }
    setOpenDrawer(show);
    setDrawerTitle({ displayName: displayName, name: name } || null);
  };

  useEffect(() => {
    const processPermissions = (permissionsData) => {
      return permissionsData.reduce((acc, perm) => {
        acc[perm.resourceName] = {
          accessLevelId: perm.accessLevelId,
          ...perm,
        };
        return acc;
      }, {});
    };

    const fetchOrganizationData = async () => {
      try {
        setLoading(true);

        // Fetch modules
        const modulesResponse = await API.get(
          `/1/roles/organization/menuresource?organizationId=${organizationId}`
        );
        setModules(modulesResponse.data);

        // Fetch default role or process permissions
        if (userOrganizationId && callDefaultAPI) {
          await fetchDefaultRole(userOrganizationId);
        } else if (orgInfo?.role) {
          const initialPermissions = processPermissions(
            orgInfo.role.permission || []
          );

          setPermissions(initialPermissions);
          setPermissionId({
            roleId: orgInfo.role.id,
            hierarchy: orgInfo.role.hierarchy,
          });
          setInitialPermissions(initialPermissions);
        }

        // Process permissions for editRoleId
        if (editRoleId) {
          const initialPermissions = processPermissions(rolePermissions || []);

          setPermissions(initialPermissions);
          setPermissionId({
            roleId: editRoleId,
            hierarchy: roleHierarchy,
          });
          setInitialPermissions(initialPermissions);
        }
      } catch (error) {
        console.error("Error fetching organization data:", error.message);
        setToastMessage(error.message);
        setToastType(false);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizationData();
  }, [
    userOrganizationId,
    rolePermissions,
    orgInfo?.role,
    organizationId,
    editRoleId,
    callDefaultAPI,
  ]);

  // Separate function to fetch the default role
  const fetchDefaultRole = async (userOrganizationId) => {
    try {
      const rolesResponse = await API.get(
        `/1/roles/organization/${userOrganizationId}/default/role`
      );

      // Process permissions
      const permissionsData = rolesResponse.data?.value?.permission || [];
      const initialPermissions = permissionsData.reduce((acc, perm) => {
        acc[perm.resourceName] = {
          accessLevelId: perm.accessLevelId,
          ...perm,
        };
        return acc;
      }, {});

      setPermissions(initialPermissions);
      setPermissionId({
        roleId: rolesResponse.data?.value.id,
        hierarchy: rolesResponse.data?.value.hierarchy,
      });
      setInitialPermissions(initialPermissions);
    } catch (error) {
      setToastMessage(error.message);
      setToastType(false);
    }
  };

  const menuItems = modules
    ?.filter(
      (item) =>
        item.type === "Menu" && !item.displayName.includes("Work-In-Progress")
    )
    .sort((a, b) => a.displayName.localeCompare(b.displayName));

  const subMenuItems = modules.filter((item) => item.type === "SubMenu");

  const handlePermissionChange = (id, value, item) => {
    setPermissions((prevPermissions) => {
      const existingEntry = Object.values(prevPermissions).find(
        (permission) => permission.displayName === item.displayName
      );

      if (existingEntry) {
        // Update `accessLevelId` for the matching entry
        return {
          ...prevPermissions,
          [id]: {
            ...existingEntry,
            accessLevelId: value,
          },
        };
      } else {
        // Add a new entry
        return {
          ...prevPermissions,
          [id]: {
            ...item,
            accessLevelId: value,
          },
        };
      }
    });

    setChangedPermissions((prevChangedPermissions) => {
      const existingEntry = Object.values(prevChangedPermissions).find(
        (permission) => permission.displayName === item.displayName
      );

      if (existingEntry) {
        // Update `accessLevelId` for the matching entry
        return {
          ...prevChangedPermissions,
          [id]: {
            ...existingEntry,
            accessLevelId: value,
          },
        };
      } else {
        // Add a new entry
        return {
          ...prevChangedPermissions,
          [id]: {
            ...item,
            accessLevelId: value,
          },
        };
      }
    });

    if (!isEdit) {
      setOrgInfo((prevOrgInfo) => {
        const updatedPermissions = Object.values(
          prevOrgInfo.role.permission || {}
        ).map((permission) =>
          permission.displayName === item.displayName
            ? { ...permission, accessLevelId: value }
            : permission
        );

        const isExisting = updatedPermissions.some(
          (permission) => permission.displayName === item.displayName
        );

        // Add a new entry if it doesn't exist
        if (!isExisting) {
          updatedPermissions.push({
            ...item,
            accessLevelId: value,
          });
        }

        return {
          ...prevOrgInfo,
          role: {
            ...prevOrgInfo.role,
            permission: updatedPermissions,
          },
        };
      });
    }

    if ((user?.isSuperAdmin || user?.isAdmin) && isEdit) {
      handleSuperAdminActions(id, { ...item, accessLevelId: value });
    }
  };

  const handleSuperAdminActions = (_id, value) => {
    if (value.accessLevelId === "3") {
      const isSubmenuExists = subMenuItems.filter(
        (item) => value.type === "Menu" && item.parentId === value.parentId
      );

      if (isSubmenuExists.length === 0) {
        openSidePanel(undefined, true, value?.displayName, value?.name);
        setShowBiInput(value.isAdditionalConfiguration);
        dispatch(
          getEnableUserList({
            organizationId: userOrganizationId,
            roleId: permissionId.roleId,
            hierarchy: permissionId.hierarchy,
            resourceName: value.name,
            resourceAdditionalConfiguration: value.isAdditionalConfiguration,
          })
        );
      }
    } else if (value.accessLevelId === "2") {
      setDisableMsg(
        "Are you sure you want to disable access? This action will remove access for all users in the organization."
      );
    }
  };

  const renderSettingsControl = (menu) => {
    if (!showSetting) return null;

    const {
      subParentId,
      name,
      displayName,
      isAdditionalConfiguration,
      type,
      categoryType,
    } = menu;

    const encodedOrgId = btoa(userOrganizationId);

    // Mapping display names to URLs
    const urlMap = {
      Booking: `/booking/configurationSettings/${encodedOrgId}`,
      "Track & Trace": `/TrackAndTrace/Settings/${encodedOrgId}`,
      "Summary Grid": `/summaryGrid/Settings/${encodedOrgId}`,
      Analytics: `/analytics/Settings/0`,
      "Shipment Summary": `/shipmentsummary/Settings/${encodedOrgId}`,
    };

    const redirectUrl = urlMap[displayName];

    const handleNavigate = (url) =>
      navigate(url, {
        state: { settingOrgId: userOrganizationId },
      });

    const renderTooltip = (url) => (
      <Tooltip title="Settings" followCursor>
        <SettingsIcon
          id={`${name}SettingsIcon`}
          style={{ marginRight: "10px" }}
          onClick={() => handleNavigate(url)}
        />
      </Tooltip>
    );

    if (redirectUrl) {
      return renderTooltip(redirectUrl);
    }

    if (
      type?.toLowerCase() === "submenu" &&
      categoryType.toLowerCase() === "submenu" &&
      isAdditionalConfiguration === false &&
      menu.parentId === "5"
    ) {
      return renderTooltip(`/analytics/Settings/${subParentId}`);
    }

    return null;
  };

  const handleAccordionChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  /**
   * Reset to original Permission
   */
  const handleReset = () => {
    setPermissions(initialPermissions); // Reset to original permissions
    if (!isEdit) {
      setOrgInfo((prev) => ({
        ...prev,
        role: {
          ...prev.role,
          permission: Object.values(initialPermissions),
        },
      }));
    }
    setChangedPermissions({}); // Clear changed permissions
  };

  const resourcePermissionsArray = changedPermissions
    ? Object.values(changedPermissions)
    : [];
  const permissionsArray = permissions ? Object.values(permissions) : [];

  // Helper function to check if all permissions of a specific type are disabled
  const areAllPermissionsDisabled = (filterCondition, accessLevelId = "2") =>
    permissionsArray
      .filter(filterCondition)
      .every((perm) => perm.accessLevelId === accessLevelId);

  // Helper function to find a module
  const findModule = (condition) => modules?.find(condition);

  const enhancedResourcePermissions = [
    ...new Map(
      resourcePermissionsArray
        ?.flatMap((res) => {
          if (res.categoryType === "TertiaryMenu") {
            const subMenu = findModule(
              (perm) =>
                perm.subParentId === res.subParentId &&
                perm.type === "SubMenu" &&
                perm.categoryType !== "TertiaryMenu"
            );
            const menu = findModule(
              (perm) => perm.parentId === res.parentId && perm.type === "Menu"
            );

            const allTertiaryMenusDisabled = areAllPermissionsDisabled(
              (perm) =>
                perm.subParentId === res.subParentId &&
                perm.categoryType === "TertiaryMenu"
            );
            const allSubMenusDisabled = areAllPermissionsDisabled(
              (perm) =>
                perm.parentId === res.parentId && perm.type === "SubMenu"
            );

            // Update access levels based on conditions
            if (allTertiaryMenusDisabled && subMenu) {
              subMenu.accessLevelId = "2";
            } else if (subMenu) {
              subMenu.accessLevelId = "3";
            }

            if (allSubMenusDisabled && menu) {
              menu.accessLevelId = "2";
            } else if (menu) {
              menu.accessLevelId = "3";
            }

            return [res, subMenu, menu].filter(Boolean);
          } else if (res.type === "SubMenu") {
            const menu = findModule(
              (perm) => perm.parentId === res.parentId && perm.type === "Menu"
            );

            const allSubMenusDisabled = areAllPermissionsDisabled(
              (perm) =>
                perm.parentId === res.parentId && perm.type === "SubMenu"
            );

            if (allSubMenusDisabled && menu) {
              menu.accessLevelId = "2";
              return [res, menu];
            }

            if (menu) {
              menu.accessLevelId = "3";
            }
            return [res, menu].filter(Boolean);
          }

          return [res];
        })
        ?.filter(Boolean)
        ?.map((item) => [item.resourceName || item.name, item])
    ).values(),
  ];

  const handleSave = async () => {
    const { roleId } = permissionId || {};
    const transformResourcePermissions = (resources) =>
      resources?.map((res) => ({
        accessLevelId: res.accessLevelId,
        resourceName: res.resourceName || res.name,
        resourceDisplayName: res.resourceDisplayName || res.displayName,
        type: res.type,
        parentId: res.parentId,
        categoryType: res.categoryType,
        subParentId: res.subParentId,
      }));

    const payload = {
      roleId,
      resourcePermissions: transformResourcePermissions(
        enhancedResourcePermissions
      ),
      userList: null,
      analyticsCardDetails: null,
    };

    try {
      setIsDisableload(true);
      const response = await API.put(`/1/roles/modify/resource`, payload);

      if (response.status !== 200) {
        console.error("Save failed:", response);
        setToastMessage(
          "Something went wrong at server. Please contact administrator"
        );
        setToastType(false);
        return setIsDisableload(false);
      }

      setToastMessage(
        "Permissions are being updated, and the changes will reflect shortly."
      );
      setToastType(true);
      setDisableMsg("");
      setIsDisableload(false);

      if (callDefaultAPI) {
        fetchDefaultRole(userOrganizationId);
      } else {
        dispatch(setRoleData(editRoleId));
      }
      setChangedPermissions({});
    } catch (error) {
      console.error("Error occurred while saving permissions:", error.message);
      setToastMessage(error.message);
      setToastType(false);
      setIsDisableload(false);
    }
  };

  if (loading)
    return (
      <div style={{ position: "relative", minHeight: "250px" }}>
        <Loader />
      </div>
    );

  return (
    <>
      <div>
        {menuItems?.map((menuItem, index) => {
          const submenuExists = subMenuItems.some(
            (subItem) => subItem.parentId === menuItem.parentId
          );

          return (
            <Accordion
              key={menuItem.id}
              className={classes.accordian}
              expanded={expanded === menuItem.id}
              onChange={submenuExists && handleAccordionChange(menuItem.id)}
            >
              <AccordionSummary
                aria-controls={`${menuItem.id}-content`}
                id={`${menuItem.id}-header`}
                sx={{
                  backgroundColor: index % 2 === 0 ? "#ffffff" : "#f5f5f5",
                }}
                className={classes.accordianSummary}
                expandIcon={
                  <img
                    src={DropArrowIcon}
                    alt="DropArrowIcon"
                    id="DropArrowIcon"
                    style={{ visibility: !submenuExists && "hidden" }}
                  />
                }
              >
                <Box display="flex" alignItems="center" width="100%">
                  <Typography
                    sx={{ flexGrow: 1 }}
                    className={classes.pTypography}
                  >
                    {menuItem.displayName}
                  </Typography>
                  {menuItems?.length > 0 && renderSettingsControl(menuItem)}
                  <PermissionDropdown
                    id={menuItem.name}
                    value={permissions[menuItem.name]?.accessLevelId}
                    onChange={handlePermissionChange}
                    item={menuItem}
                    classes={classes}
                    isSubMenu={false}
                  />
                </Box>
              </AccordionSummary>
              {submenuExists && (
                <AccordionDetails>
                  {subMenuItems
                    .filter((subItem) => subItem.parentId === menuItem.parentId)
                    .map((subItem, index) => (
                      <Box
                        key={subItem.id}
                        display="flex"
                        alignItems="center"
                        width="100%"
                        sx={{
                          borderBottom: "1px solid #ccc",
                          backgroundColor:
                            index % 2 === 0 ? "#ffffff" : "#f5f5f5",
                        }}
                      >
                        <Typography
                          sx={{ flexGrow: 1 }}
                          className={
                            subItem.categoryType === "TertiaryMenu"
                              ? classes.childChildTypography
                              : classes.childTypography
                          }
                        >
                          <div style={{ display: "flex" }}>
                            {subItem.categoryType === "TertiaryMenu" && (
                              <SubdirectoryArrowRightIcon color="red" />
                            )}
                            {subItem.displayName}
                          </div>
                        </Typography>
                        {subMenuItems.length > 0 &&
                          renderSettingsControl(subItem)}
                        <PermissionDropdown
                          id={subItem.name}
                          value={permissions[subItem.name]?.accessLevelId}
                          onChange={handlePermissionChange}
                          item={subItem}
                          classes={classes}
                          isSubMenu={true}
                        />
                      </Box>
                    ))}
                </AccordionDetails>
              )}
            </Accordion>
          );
        })}
      </div>
      <div>
        <ManagePermission
          openDrawer={openDrawer}
          openSidePanel={openSidePanel}
          drawerTitle="Manage Permissions"
          showBiInput={showBiInput}
          userOrganizationId={userOrganizationId}
          handleReset={handleReset}
          roleId={permissionId?.roleId}
          resourcePermissions={enhancedResourcePermissions}
          drawerSubTitle={drawerTitle}
          fetchDefaultRole={fetchDefaultRole}
          callDefaultAPI={callDefaultAPI}
          editRoleId={editRoleId}
          setChangedPermissions={setChangedPermissions}
        />
      </div>
      <FrachtDeleteDialogBox
        open={Boolean(disableMsg)}
        message={disableMsg}
        handleClose={(_e) => {
          setDisableMsg("");
          handleReset();
        }}
        success={true}
        performAction={() => {
          handleSave();
        }}
        modalTitle="Disable Access"
        modalType="Warning"
        isDeleting={isDisableload}
        cancelButton="Cancel"
        saveButton="Confirm"
      />
      <Toast
        open={Boolean(toastMessage)}
        message={toastMessage}
        handleClose={() => setToastMessage("")}
        success={toastType}
      />
    </>
  );
};

export default AppPermission;

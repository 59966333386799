import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "../../assets/images/SearchIcon.png";
import FracgtTextArea from "../../commonControls/frachtTextArea";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import FrachtButtonPanel from "../../commonControls/FrachtButtonPanel";
import { resetEnablePermission, setRoleData } from "../../redux/actions";
import Loader from "../UI/Loader/Loader";
import Toast from "../UI/Toast/Toast";
import API from "../../utils/axios";

const useStyles = makeStyles((theme) => ({
  iconDiv: {
    textAlign: "end",
  },
  icon: {
    cursor: "pointer",
    width: "32px",
    height: "32px",
    color: "#212529",
  },
  title: {
    textAlign: "left",
    font: "normal normal 600 24px Open Sans",
    color: "#183650",
  },
  subTitle: {
    textAlign: "left",
    color: "grey",
    ...theme.typography.h6,
  },
  searchIcon: {
    position: "relative",
  },
  searchInput: {
    width: "-webkit-fill-available",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid #18365073",
    backgroundColor: theme.palette.color.white,
    padding: "10px",
    margin: "10px 0",
    ...theme.typography.body2,
    "&::placeholder": {
      ...theme.typography.body2,
      color: theme.palette.color.lightGrayishBlueB3,
      opacity: 1,
    },
    "&:focus-within ": {
      outline: "none",
      border: "1px solid #18365073",
    },
    "&:hover ": {
      outline: "none",
      border: "1px solid #18365073",
    },
    "&:before ": {
      border: "none !important",
    },
    "& input": {
      padding: "0px",
    },
  },
  drawerBody: {
    flexGrow: 1,
    marginBottom: "86px",
    height: "calc(96% - 9%)",
  },
  listContainer: {
    border: "1px solid rgb(24 54 80 / 9%)",
    borderRadius: "6px",
    padding: 0,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    overflow: "auto",
    height: "auto",
    maxHeight: "75%",
  },
  listItem: {
    borderBottom: "1px solid #e0e0e0",
    padding: "3px 16px",
    "& span": {
      ...theme.typography.body2,
    },
    "&:hover": {
      color: `${theme.palette.color.skyBlue} !important`,
      opacity: 1,
    },
  },
  label: {
    fontSize: "16px",
    fontWeight: 500,
  },
}));

function not(a, b) {
  return a.filter((value) => !b.includes(value));
}

function intersection(a, b) {
  return a.filter((value) => b.includes(value));
}

const ManagePermission = ({
  openSidePanel,
  openDrawer,
  drawerTitle,
  userOrganizationId,
  showBiInput,
  handleReset,
  resourcePermissions,
  roleId,
  drawerSubTitle,
  fetchDefaultRole,
  callDefaultAPI,
  editRoleId,
  setChangedPermissions,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { organizationList, userList, permissionLoading } = useSelector(
    (state) => state.EditOrganization
  );
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [powerBIURL, setPowerBIURL] = useState("");
  const [analyticsCardName, setAnalyticsCardName] = useState(null);
  const [orgData, setOrgData] = useState([]);

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [searchQueryLeft, setSearchQueryLeft] = useState("");
  const [searchQueryRight, setSearchQueryRight] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState(false);
  const [loading, setLoading] = useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    if (userList) {
      const { unAssignedUsers, assignedUsers, analyticsCardDetails } = userList;
      setLeft(unAssignedUsers || []);
      setRight(assignedUsers || []);
      if (analyticsCardDetails) {
        const { analyticsCardURL, organization, analyticsCard } =
          analyticsCardDetails;
        setPowerBIURL(analyticsCardURL);
        setAnalyticsCardName(analyticsCard);
        if (organization?.length > 0) {
          setOrgData(organization);
        } else {
          const defaultOrg = organizationList?.find(
            (org) => org.organizationId === userOrganizationId
          );
          if (defaultOrg) {
            setOrgData([defaultOrg]);
          }
        }
      } else {
        const defaultOrg = organizationList?.find(
          (org) => org.organizationId === userOrganizationId
        );
        if (defaultOrg) {
          setOrgData([defaultOrg]);
        }
      }
    }
  }, [userList]);

  const handleUrlChange = (event) => {
    setPowerBIURL(event.target.value);
  };

  const fetchOrganizationName = (id, list) => {
    const organization = list.find((org) => org.organizationId === id);
    return organization ? organization.organizationName : "";
  };

  // for selections
  const handleToggle = (value) => () => {
    const currentIndex = checked?.findIndex(
      (item) => item.email === value.email
    );
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
      if (left.includes(value)) {
        moveItemToRight(value);
      }
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const moveItemToRight = (item) => {
    setRight([...right, item]);
    setLeft(left.filter((user) => user.email !== item.email));
    setChecked((prev) => prev.filter((value) => value.email !== item.email)); // Remove from checked
  };

  const moveItemToLeft = (item) => {
    setLeft([...left, item]);
    setRight(right.filter((user) => user.email !== item.email));
    setChecked((prev) => prev.filter((value) => value.email !== item.email)); // Remove from checked
  };

  // Select all
  const numberOfChecked = (items) => intersection(checked, items).length;

  // Select all for Left list
  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(checked.concat(not(items, checked)));
      moveAllToRight(items);
    }
  };

  const moveAllToRight = (items) => {
    setRight([...right, ...items]);
    setLeft([]);
    setChecked([]);
  };

  // Select All for Right List
  const handleToggleAllRight = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(checked.concat(not(items, checked)));
      moveAllToLeft(items);
    }
  };

  const moveAllToLeft = (items) => {
    setLeft([...left, ...items]);
    setRight([]);
    setChecked([]);
  };

  // Search Handlers
  const handleSearchLeft = (event) => {
    setSearchQueryLeft(event?.target?.value?.toLowerCase());
  };

  const handleSearchRight = (event) => {
    setSearchQueryRight(event?.target?.value?.toLowerCase());
  };

  // Filtered Lists
  const filteredLeft = left?.filter(
    (user) =>
      user?.displayName.toLowerCase()?.includes(searchQueryLeft) ||
      user?.email?.toLowerCase()?.includes(searchQueryLeft)
  );

  const filteredRight = right?.filter(
    (user) =>
      user?.displayName.toLowerCase()?.includes(searchQueryRight) ||
      user?.email?.toLowerCase()?.includes(searchQueryRight)
  );

  const onClose = (e) => {
    openSidePanel(e, false);
    dispatch(resetEnablePermission());
    setLeft([]);
    setRight([]);
    setChecked([]);
    setSearchQueryLeft("");
    setSearchQueryRight("");
    setPowerBIURL("");
    setAnalyticsCardName(null);
    setOrgData([]);
  };

  const handleSave = async (e) => {
    const transformResourcePermissions = (resources) =>
      resources?.map((res) => ({
        accessLevelId: res.accessLevelId,
        resourceName: res.resourceName || res.name,
        resourceDisplayName: res.resourceDisplayName || res.displayName,
        type: res.type,
        parentId: res.parentId,
        categoryType: res.categoryType,
        subParentId: res.subParentId,
      }));

    const payload = {
      roleId,
      resourcePermissions: transformResourcePermissions(resourcePermissions),
      userList: {
        unAssignedUsers: left?.map((user) => user.email),
        assignedUsers: right?.map((user) => user.email),
      },
      analyticsCardDetails: showBiInput
        ? {
            organization: orgData?.map((org) => ({
              organizationId: org?.organizationId,
              organizationName: org?.organizationName,
            })),
            analyticsCardURL: powerBIURL,
            analyticsCard: analyticsCardName
              ? analyticsCardName
              : drawerSubTitle?.name?.replace("Analytics", "") + "URL",
          }
        : null,
    };

    try {
      setLoading(true);
      const response = await API.put(`/1/roles/modify/resource`, payload);

      if (response.status !== 200) {
        console.error("Save failed:", response);
        setToastMessage(
          "Something went wrong at server. Please contact administrator"
        );
        setToastType(false);
        return setLoading(false);
      }

      setToastMessage(
        "Permissions are being updated, and the changes will reflect shortly."
      );
      setToastType(true);
      setLoading(false);

      if (callDefaultAPI) {
        fetchDefaultRole(userOrganizationId);
      } else {
        dispatch(setRoleData(editRoleId));
      }
      onClose(e);
      setChangedPermissions({});
    } catch (error) {
      console.error("Error occurred while saving permissions:", error.message);
      setToastMessage(error.message);
      setToastType(false);
      setLoading(false);
    }
  };

  const customList = (title, items, isLeft) => (
    <Box
      mt={2}
      style={{
        width: "100%",
        height: "50%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <button
          onClick={
            isLeft ? handleToggleAll(items) : handleToggleAllRight(items)
          }
          style={{
            background: "none",
            border: "none",
            color: "#007bff",
            textDecoration: "underline",
            cursor: "pointer",
            margin: "0",
            display: items?.length === 0 ? "none" : "inline",
          }}
        >
          {isLeft ? "Assign All" : "Unassign All"}
        </button>
      </div>

      <Input
        className={classes.searchInput}
        placeholder="Search User"
        type="search"
        value={isLeft ? searchQueryLeft : searchQueryRight}
        onChange={isLeft ? handleSearchLeft : handleSearchRight}
        endAdornment={
          <InputAdornment position="end">
            <img
              src={SearchIcon}
              alt="SearchIcon"
              className={classes.searchIcon}
            />
          </InputAdornment>
        }
      />

      <List className={classes.listContainer}>
        {items?.length === 0 ? (
          <div>
            <Typography variant="body2" sx={{ textAlign: "center", margin: 2 }}>
              No user found
            </Typography>
          </div>
        ) : (
          items?.map((value, index) => {
            return (
              <ListItem
                key={value.email}
                style={{
                  backgroundColor: index % 2 === 0 ? "#F7F7F7" : "#FFFFFF",
                  cursor: isLeft ? "pointer" : "default",
                }}
                className={classes.listItem}
                onClick={isLeft && handleToggle(value)}
              >
                <ListItemText
                  primary={`${value.displayName} (${value.email})`}
                />
                {!isLeft && (
                  <IconButton onClick={() => moveItemToLeft(value)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItem>
            );
          })
        )}
      </List>
    </Box>
  );

  return (
    <>
      <Toast
        open={Boolean(toastMessage)}
        message={toastMessage}
        handleClose={() => setToastMessage("")}
        success={toastType}
      />
      <Drawer
        anchor={isMobile ? "bottom" : "right"}
        open={openDrawer}
        PaperProps={{
          sx: {
            backgroundColor: "#FCFCFC",
            width: isMobile ? "100%" : "calc(100vh - 18%)",
            overflowY: isMobile ? "auto" : "hidden",
            maxHeight: isMobile ? "60vh" : "100%",
          },
        }}
      >
        {(permissionLoading || loading) && <Loader />}
        <Box
          sx={{
            padding: "14px 24px 0 24px",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
          role="presentation"
        >
          <Grid container>
            <Grid item xs={10}>
              <Typography className={classes.title}>{drawerTitle}</Typography>
              <Typography className={classes.subTitle}>
                ({drawerSubTitle?.displayName})
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.iconDiv}>
              <CloseIcon
                className={classes.icon}
                onClick={(e) => {
                  handleReset();
                  onClose(e);
                }}
              />
            </Grid>
          </Grid>
          <div className={classes.drawerBody}>
            {showBiInput && (
              <>
                <FracgtTextArea
                  placeholder="Add Power BI URL"
                  label="Power BI URL"
                  minRows={2}
                  maxRows={3}
                  sx={{ margin: "14px 0" }}
                  value={powerBIURL}
                  onChange={handleUrlChange}
                />
                <Autocomplete
                  multiple
                  limitTags={2}
                  required
                  disableCloseOnSelect
                  disableClearable
                  id="organizationField"
                  value={orgData}
                  onChange={(e, newValue) => {
                    setOrgData(newValue);
                  }}
                  options={organizationList}
                  getOptionLabel={(option) =>
                    option.organizationName ||
                    fetchOrganizationName(
                      option.organizationId,
                      organizationList
                    )
                  }
                  getOptionDisabled={(option) =>
                    orgData?.some(
                      (selectedOption) =>
                        selectedOption.organizationId === option.organizationId
                    ) || userOrganizationId === option.organizationId
                  }
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        key={option.organizationId}
                        label={
                          option.organizationName ||
                          fetchOrganizationName(
                            option.organizationId,
                            organizationList
                          )
                        }
                        {...getTagProps({ index })}
                        disabled={userOrganizationId === option.organizationId}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.organizationId}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={
                          selected ||
                          orgData?.some(
                            (org) =>
                              org.organizationId === option.organizationId
                          )
                        }
                      />
                      {option.organizationName ||
                        fetchOrganizationName(
                          option.organizationId,
                          organizationList
                        )}
                    </li>
                  )}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Organization"
                      placeholder="Select Organization"
                      InputLabelProps={{
                        className: classes.label,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        classes: { input: classes.label },
                      }}
                    />
                  )}
                />
              </>
            )}

            <Grid
              container
              mb={2}
              style={{
                height: showBiInput ? "70%" : "95%",
              }}
            >
              {customList("Select Users", filteredLeft, true)}
              {customList("Selected Users", filteredRight, false)}
            </Grid>
          </div>
          <FrachtButtonPanel
            firstButtonText="Cancel"
            firstHandler={(e) => {
              handleReset();
              onClose(e);
            }}
            secondButtonText="Apply"
            secondHandler={(e) => {
              handleSave(e);
            }}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default ManagePermission;

import * as React from "react";
import PortDwellIcon from "../../../assets/images/portdwell.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Slider,
  Tooltip,
  Typography,
} from "@mui/material";
import { widgetStyles } from "../widgetStyles";
import { getPortDwell } from "../../../redux/widgets/action";
import { debounce } from "lodash";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function PortDwell() {
  const classes = widgetStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organizationId } = useSelector((state) => state.LoginUser);
  const { portDwellData, cardLoadingPortDwell, portDwellSliderValue } =
    useSelector((state) => state.WidgetsReducer);
  const [selectedVal, setSelectedVal] = React.useState(portDwellSliderValue);
  const [vesselCount, setVesselCount] = React.useState(portDwellData);

  const debouncedDispatch = React.useCallback(
    debounce((organizationId, value) => {
      dispatch(getPortDwell(organizationId, value));
    }, 1000),
    [dispatch]
  );

  function valuetext(e) {
    setSelectedVal(e.target.value);
    debouncedDispatch(organizationId, e.target.value);
  }

  useEffect(() => {
    if (organizationId && portDwellData === 0) {
      dispatch(getPortDwell(organizationId, selectedVal));
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    setVesselCount(portDwellData);
  }, [portDwellData]);

  const redirectToOrders = () => {
    const gridId = "POSummary";
    localStorage.removeItem(`${gridId}-viewName`);
    localStorage.removeItem(`grid-${gridId}`);
    localStorage.removeItem(`grid-${gridId}-colums`);
    localStorage.removeItem(`${gridId}-status-card`);
    navigate(`/shipmentsummary/live/portdwell`, {
      state: {
        dwellTimeDays: selectedVal,
      },
    });
  };

  return (
    <Card sx={{ width: "100%", borderRadius: "22px" }}>
      <CardHeader
        title={
          <div style={{ display: "flex", margin: "24px 0 -24px 4px" }}>
            <Slider
              classes={{
                thumb: classes.thumb,
                rail: classes.rail,
                track: classes.track,
                mark: classes.mark,
              }}
              size="small"
              defaultValue={selectedVal}
              valueLabelDisplay="auto"
              step={1}
              marks={marks}
              min={1}
              max={20}
              onChange={valuetext}
              data-testid="Slider"
            />

            <Typography
              style={{
                width: "90px",
                height: "19px",
                background: "#ffffff",
                fontSize: "14px",
                textAlign: "right",
                display: "flex",
                paddingLeft: "4px",
              }}
            >
              <div>{`${selectedVal} days`}</div>
              <div style={{ paddingLeft: "4px" }}>
                <Tooltip
                  placement="bottom-start"
                  title="Adjustable tolerance in days."
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </div>
            </Typography>
          </div>
        }
      />
      <Divider />
      <div
        component="div"
        onClick={redirectToOrders}
        sx={{
          cursor: "pointer",
        }}
      >
        <CardContent className={classes.dwellCardContent}>
          <img
            src={PortDwellIcon}
            className={classes.statusIcon}
            alt="dwellAtTransshipPortIcon"
          />
          <div className={classes.statusTextContainer}>
            <Typography
              variant="body22"
              className={classes.statusText}
              color="color.primary"
            >
              {`Number of containers at port for ${selectedVal} days or more`}
            </Typography>
            <Typography
              variant="h2A"
              className={classes.statusCount}
              color="#001737"
            >
              {cardLoadingPortDwell ? <CircularProgress /> : vesselCount}
            </Typography>
          </div>
        </CardContent>
      </div>
    </Card>
  );
}

export const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 20,
    label: "20",
  },
];
